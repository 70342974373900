import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store";
import {AUTHENTIFICATION_STATUS} from "@/constants/AuthentificationStatus";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Tableau de bord',
        component: () => import("../views/Home"),
    },
    {
        path: '/profil',
        name: 'Profil',
        component: () => import("../views/Profil"),
    },
    {
        path: '/login',
        name: 'Se connecter',
        component: () => import("../views/Login"),
    },
    {
        path: '/dossier',
        name: 'Dossiers',
        component: () => import('../views/Dossiers')
    },
    {
        path: '/dossier/create',
        name: 'Nouveau dossier',
        component: () => import('../views/NewDossier.vue')
    },
    {
        path: '/dossier/:id',
        name: 'Détail du dossier',
        component: () => import('../views/DossierView.vue')
    },
    {
        path: '/admin',
        name: 'Paramètres',
        component: () => import('../views/Admin.vue')
    },
    {
        path: '/admin/referentiel',
        name: 'Référentiel de prix',
        component: () => import('../views/Referentiel.vue')
    },
    {
        path: '/admin/modeldocs',
        name: 'Modèles de document',
        component: () => import('../views/ModelDocs.vue')
    },
    {
        path: '/admin/statuts',
        name: 'Statuts de dossier',
        component: () => import('../views/Statuts.vue')
    },
    {
        path: '/admin/users',
        name: 'Utilisateurs',
        component: () => import('../views/Users.vue')
    },
    {
        path: '/admin/select',
        name: 'Champs à choix multiples',
        component: () => import('../views/FieldSelect.vue')
    },
    {
        path: '/admin/objectifs',
        name: 'Définir les objectifs',
        component: () => import('../views/Goals.vue')
    },
    {
        path: '/devis/:id',
        name: 'Détails du devis',
        component: () => import('../views/DevisView.vue')
    },
    {
        path: '/entreprises',
        name: 'Liste des entreprises',
        component: () => import('../views/EntrepriseList.vue')
    },
    {
        path: '/entreprises/:id',
        name: 'Détail de l\'entreprise',
        component: () => import('../views/EntrepriseDetails.vue')
    },
    {
        path: '/travaux/:id',
        name: 'Travaux du dossier',
        component: () => import('../views/TravauxDetails.vue')
    },
    {
        path: "/forgotpassword",
        name: "Mot de passe oublié",
        component: () => import('../views/PasswordForgotten.vue'),
        meta: {title: "Mot de passe oublié"},
    },
    {
        path: "/resetpassword",
        name: "Réinitialisation du mot de passe",
        component: () => import('../views/PasswordReset.vue'),
        meta: {title: "Mot de passe oublié"},
    },
    {
        path: '/finances',
        name: "Statut financier de l'activité",
        component: () => import('../views/Financier.vue'),
        meta: {title: "Statut financier de l'activité"}
    },
    {
        path: '/finances/:id',
        name: "Statut financier du dossier",
        component: () => import('../views/FinancierInfos.vue'),
        meta: {title: "Statut financier de l'activité"}
    },
    {
        path: '/factures',
        name: "Toutes les factures",
        component: () => import('../views/Factures.vue'),
        meta: {title: "Toutes les factures"}
    },
    {
        path: '/factures/:statut',
        name: "Factures",
        component: () => import('../views/Factures.vue'),
        meta: {title: "Factures"}
    },
    {
        path: '/financements',
        name: "Suivi des financements",
        component: () => import('../views/SuiviFinancements.vue'),
        meta: {title: "Suivi des financements"}
    },
    {
        path: '/facturation',
        name: "Suivi de la facturation",
        component: () => import('../views/SuiviFacturationGlobal.vue'),
        meta: {title: "Suivi de la facturation"}
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

const PUBLIC_ROUTES = ["Se connecter", "Mot de passe oublié", "Réinitialisation du mot de passe"];
const EXTERN_ALLOWED_ROUTES = ["Tableau de bord","Dossiers", "Détail du dossier", "Détails du devis", "Travaux du dossier"].concat(PUBLIC_ROUTES);

router.beforeEach(async function (to, from, next) {
    let isLogged = false;
    if (store.getters["auth/getStatus"] !== AUTHENTIFICATION_STATUS.LOGGED_IN) {
        let isSessionLogged = await store.dispatch("auth/isSessionLogged");
        let isStorageLogged = await store.dispatch("auth/isStorageLogged");
        isLogged = isSessionLogged || isStorageLogged;
    } else {
        isLogged = true;
    }
    let user = store.getters["auth/getLoggedUser"];
    if (!isLogged && !PUBLIC_ROUTES.includes(to.name)) {
        next({name: "Se connecter", query: to.query.path});
    } else if (isLogged && to.query.path !== undefined) {
        next(to.query.path);
    } else if(user !== null && ["Maître d'oeuvre", "Conducteur de travaux"].includes(user.group) && !EXTERN_ALLOWED_ROUTES.includes(to.name)) {
        await store.dispatch("annonce/annonceError", "Vous n'êtes pas autorisé à accéder à cette page");
        next("/");
    }
    else {
        document.title = to.name + " - ARCAVS";
        next();
    }
});

export default router
