import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from "axios";
import * as VueGoogleMaps from 'vue2-google-maps'
import VueApexCharts from 'vue-apexcharts'
import * as config from './config';
import dayjs from "dayjs";
Vue.config.productionTip = false
axios.defaults.baseURL = config.BASE_URL;
require("dayjs/locale/fr")

Vue.use(VueApexCharts)

dayjs.locale("fr")
import * as Sentry from "@sentry/vue";

if (config.MODE !== "development") {
  Sentry.init({
    Vue,
    dsn: "https://7cfa5ed0463b4d46a0f6653711ee098b@o365576.ingest.sentry.io/4504997597937664",
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: [config.APP_DOMAIN || "arcavs.solutia.live", /^\//],
      }),
    ],
    tracesSampleRate: 0.75,
  });
}
window.dayjs = dayjs

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBmbcgHlY8s7HYQpAN725UBuwUHQGQIoXw',
    libraries: "places",
  }
})
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
