import axios from "axios";

export const AttachmentStore = {
    namespaced: true,
    state: () => ({}),
    mutations: {},
    getters: {},
    actions: {
        async postDossierAttachment({dispatch, rootGetters}, attachment) {
            let formData = new FormData();
            formData.append("label", attachment.label);
            formData.append("file", attachment.file);
            formData.append("dossier", attachment.dossier);
            formData.append("type", attachment.type);
            formData.append("description", attachment.description);
            let axiosConfig = rootGetters["auth/getAuth"];
            let response = await axios.post(`/api/attachments/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                ...axiosConfig
            });
            if (response.status === 201 || response.status === 200) {
                return "success";
            } else {
                dispatch("annonce/annonceError", "Une erreur est survenue", {root:true});
                return "failure";
            }
        },
        async patchAttachment({dispatch, rootGetters}, attachment) {
            let axiosConfig = rootGetters["auth/getAuth"];
            let response = await axios.patch(`/api/attachments/${attachment.id}/`, attachment, axiosConfig);
            if (response.status === 201 || response.status === 200) {
                return "success";
            } else {
                dispatch("annonce/annonceError", "Une erreur est survenue", {root:true});
                return "failure";
            }
        },
        async postMultipleAttachments({dispatch}, payload) {
            let formData = new FormData();
            payload.files.forEach((file) => formData.append("files",file));
            formData.append("is_photo", payload.is_photo);
            formData.append("dossier", payload.dossier);
            let response = await axios.post(`/api/attachments/multiple/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (response.status === 201 || response.status === 200) {
                return "success";
            } else {
                dispatch("annonce/annonceError", "Une erreur est survenue", {root:true});
                return "failure";
            }
        },
        async deleteDossierAttachment({dispatch, rootGetters}, attachment) {
            try {
                await axios.delete(`/api/attachments/${attachment.id}/`, rootGetters["auth/getAuth"]);
                return "success";
            } catch (e) {
                dispatch("annonce/annonceError", "Une erreur est survenue", {root:true});
                return "failure";
            }
        },
        async diviseDocuments({dispatch, rootGetters}, payload) {
            let axiosConfig = rootGetters["auth/getAuth"];
            let response = await axios.post(`/api/attachments/division/`, payload, axiosConfig);
            if (response.status === 201 || response.status === 200) {
                return response?.data?.message;
            } else {
                dispatch("annonce/annonceError", "Une erreur est survenue", {root:true});
                return "failure";
            }
        }
    }
}