import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.link.children === undefined || _vm.link.children.length === 0)?_c(VListItem,{attrs:{"to":_vm.link.to !== null ? _vm.link.to : null},on:{"click":function($event){_vm.link.action !== null ? _vm.link.action() : null}}},[_c(VListItemIcon,{staticClass:"white--text"},[_c(VIcon,{attrs:{"color":"white"}},[_vm._v(_vm._s(_vm.link.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"white--text"},[_vm._v(_vm._s(_vm.link.name))])],1)],1):_c(VListGroup,{attrs:{"color":"white","no-action":""},scopedSlots:_vm._u([{key:"prependIcon",fn:function(){return [_c(VIcon,{attrs:{"color":"white"}},[_vm._v(_vm._s(_vm.link.icon))])]},proxy:true},{key:"activator",fn:function(){return [_c(VListItemContent,[_c(VListItemTitle,{staticClass:"white--text",domProps:{"textContent":_vm._s(_vm.link.name)}})],1)]},proxy:true}])},_vm._l((_vm.link.children),function(child){return _c(VListItem,{key:child.name,staticClass:"white--text",attrs:{"to":child.to !== null ? child.to : null},on:{"click":function($event){child.action !== null ? child.action() : null}}},[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"white--text",domProps:{"textContent":_vm._s(child.name)}})],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }