import store from "@/store";

const axios = require("axios");

/**
 * @extends IApi
 */
export class Api {
    constructor(name) {
        this.url = `/api/${name}`;
    }

    async getById(object) {
        try {
            let response = await axios.get(`${this.url}/${object.id}`, store.getters["auth/getAuth"]);
            return response.data;
        } catch (e) {
            store.dispatch("annonce/annonceError", "Une erreur est survenue");
        }
    }

    async post(object) {
        try {
            let response = await axios.post(`${this.url}/`, object, store.getters["auth/getAuth"]);
            return response.data;
        } catch (e) {
            store.dispatch("annonce/annonceError", "Une erreur est survenue");
        }
    }

    async fetch() {
        try {
            let response = await axios.get(`${this.url}/`, store.getters["auth/getAuth"]);
            return response.data;
        } catch (e) {
            await store.dispatch("annonce/annonceError", "Une erreur est survenue");
            return [];
        }
    }

    async update(object) {
        try {
            let response = await axios.patch(`${this.url}/${object.id}/`, object, store.getters["auth/getAuth"]);
            return response.data;
        } catch (e) {
            store.dispatch("annonce/annonceError", "Une erreur est survenue");
        }
    }
    async createWithFile(object) {
        let data = new FormData();
        for (const element in object) {
            if (object[element] !== null) {
                data.append(element, object[element]);
            }
        }
        try {
            let response = await axios.post(`${this.url}/`, data, store.getters["auth/getAuth"]);
            return response.data;
        } catch (e) {
            store.dispatch("annonce/annonceError", "Une erreur est survenue");
        }
    }
    async updateWithFile(object) {
        let data = new FormData();
        for (const element in object) {
            if (object[element] !== null) {
                data.append(element, object[element]);
            }
        }
        try {
            let response = await axios.patch(`${this.url}/${object.id}/`, data, store.getters["auth/getAuth"]);
            return response.data;
        } catch (e) {
            store.dispatch("annonce/annonceError", "Une erreur est survenue");
        }
    }

    async delete(object) {
        try {
            await axios.delete(`${this.url}/${object.id}`, store.getters["auth/getAuth"]);
            return "success";
        } catch (e) {
            store.dispatch("annonce/annonceError", "Une erreur est survenue");
            return "failure";
        }
    }

}