import {Api} from "@/repository/api";
import axios from "axios";
import store from "@/store";

export class ReglementRepository extends Api {
    constructor() {
        super("reglements");
    }

    async createFacturePayment(object) {
        try {
            let data = new FormData();
            for (const element in object) {
                data.append(element, object[element]);
            }
            let response = await axios.post(`${this.url}/commande/`, data, store.getters["auth/getAuth"]);
            return response.data;
        } catch (e) {
            store.dispatch("annonce/annonceError", "Une erreur est survenue");
        }
    }

    async createDemandeReglement(object) {
        try {
            let data = new FormData();
            for (const element in object) {
                data.append(element, object[element]);
            }
            let response = await axios.post(`${this.url}/subvention/`, data, store.getters["auth/getAuth"]);
            return response.data;
        } catch (e) {
            store.dispatch("annonce/annonceError", "Une erreur est survenue");
        }
    }

    async createFactureHonoraires(object) {
        try {
            let data = new FormData();
            for (const element in object) {
                data.append(element, object[element]);
            }
            await axios.post(`${this.url}/honoraires/`, data, store.getters["auth/getAuth"]);
            return "SUCCESS";
        } catch (e) {
            store.dispatch("alerts/handleAxiosError", e);
        }
    }

    async downloadBankFile(object) {
        try {

            let response = await axios({
                "url": `${this.url}/bank_export/`,
                "method": "POST",
                "data": object,
                ...store.getters["auth/getAuth"]
            });
            if (response.status === 204) {
                store.dispatch("annonce/annonceWarning", "Aucun règlement valide n'a été séléctionné.");
            }
            var binaryData = [];
            let filename = "";
            try {
                console.log(response);
                let headerLine = response.headers['content-disposition'] ?? response.headers['Content-Disposition'];
                console.log(headerLine);
                let startFileNameIndex = headerLine.indexOf('"') + 1;
                let endFileNameIndex = headerLine.lastIndexOf('"');
                filename = headerLine.substring(startFileNameIndex, endFileNameIndex);
                if (filename === null || filename === '') filename = "bank_file.xml";
            } catch (e) {
                console.error(e);
                filename = "bank_file.xml";
            }
            binaryData.push(response.data);
            const url = URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');

            link.setAttribute('download', filename);
            link.target = "_blank";
            link.href = url;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        } catch (e) {
            console.log(e.toString());
            store.dispatch("alerts/handleAxiosError", e);
        }
    }

    async fetchFactures() {
        try {
            let response = await axios.get(`${this.url}/factures/`, store.getters["auth/getAuth"]);
            return response.data;
        } catch (e) {
            await store.dispatch("annonce/annonceError", "Une erreur est survenue");
            return [];
        }
    }

    async fetchFinancements() {
        try {
            let response = await axios.get(`${this.url}/financements/`, store.getters["auth/getAuth"]);
            return response.data;
        } catch (e) {
            await store.dispatch("annonce/annonceError", "Une erreur est survenue");
            return [];
        }
    }

    async fetchClients() {
        try {
            let response = await axios.get(`${this.url}/clients/`, store.getters["auth/getAuth"]);
            return response.data;
        } catch (e) {
            await store.dispatch("annonce/annonceError", "Une erreur est survenue");
            return [];
        }
    }

    async goToFacture(reglement) {
        try {
            let response = await axios.get(`${this.url}/${reglement.id}/facture/`, store.getters["auth/getAuth"]);
            if(response.status === 200) {
                var url = response.data["url"]
                const link = document.createElement('a');
                link.target = "_blank";
                link.href = url;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(url);
            } else {
                store.dispatch("annonce/annonceSuccess", "Pas de facture trouvé");
            }
            return response.data;
        } catch (e) {
            await store.dispatch("annonce/annonceError", "Une erreur est survenue");
            return [];
        }
    }

    async fetchReglementsByEntrepriseId(entreprise) {
        try {
            let response = await axios.get(`${this.url}/?entreprise=${entreprise}`, store.getters["auth/getAuth"]);
            return response.data;
        } catch (e) {
            await store.dispatch("annonce/annonceError", "Une erreur est survenue");
            return [];
        }
    }
}